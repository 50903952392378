import React from 'react'

export const Balance = ({ data }) => {
  const { lev, lev2 } = data

  return (
    <article>
      <div>
        <center>
          <h2>LEV to L2 Token Migration</h2>
        </center>
      </div>
      <section>
        <div>
          <span className='title'>Token Balances</span>
        </div>
        <div>
          <span className='balance'>{lev} LEV</span>
          <span className='balance'>{lev2} L2</span>
        </div>
      </section>
    </article>
  )
}

export default Balance
