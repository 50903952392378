import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import Main from './components/Main'

import './App.css'

const getLibrary = provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Main />
    </Web3ReactProvider>
  )
}

export default App
