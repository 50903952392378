import React, { useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function MuiAlert (props) {
  return <Alert elevation={6} variant='filled' {...props} />
}

export const Chain = ({ data }) => {
  const { chain, supportedChain = true, account } = data
  const shortAcc =
    account && account.slice(0, 6) + '...' + account.slice(38, 42)

  const [snackbar, setSnackbar] = React.useState({
    open: false
  })

  const { open } = snackbar

  const handleOpen = newState => () => {
    setSnackbar({
      open: true,
      ...newState
    })
  }

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  useEffect(() => {
    setTimeout(
      // timeout to have time to get state and thus minimise flicking
      () =>
        supportedChain
          ? handleClose()
          : handleOpen({
              message: `Current chain is not supported, switch to Mainnet`,
              severity: 'error'
            })(),
      100
    )
  }, [supportedChain, handleClose])

  return (
    <div className='netInfo'>
      <span>
        Wallet:&nbsp;<b>{shortAcc}</b>
      </span>
      <span>
        Network:&nbsp;
        <b>{chain}</b>
      </span>

      <Snackbar
        open={open}
        autoHideDuration={snackbar.duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Chain
