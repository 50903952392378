import React from 'react'

export const Disclaimer = ({ agreeFn }) => (
  <section>
    <div>
      <span className='title'>Token Migration</span>
    </div>
    <div style={{ padding: '40px' }}>
      <span>
        <h2>Terms of use</h2>
        <p>
          I understand that this app is guiding me through the migration process
          and is not in possession of any private keys to conduct any operations
          related to approval or migration of LEV tokens.
        </p>
        <p>
          I understand that in order to begin the migration process I need to
          already own LEV tokens, and have access to the Ethereum account in
          possession of the LEV tokens.
        </p>
        <p>
          I understand that when I approve LEV to be migrated, I will be sending
          a transaction to the LEV token smart contract address.
        </p>
        <p>
          I understand that once I migrate the LEV tokens my balance will be
          reflected in the new L2 token contract.
        </p>
        <p>
          I understand that this is a one-way migration process and that the
          transactions I make are immutable.
        </p>
        <p>
          I understand that I am responsible for Approving and Migrating my LEV
          tokens with MetaMask or Manually (with MyEtherWallet, Jaxx, Parity,
          Mist, etc).
        </p>
        <p>
          I understand that the terms of the LEV Tokens User Agreement applies to L2 Tokens. Access and download a copy of the <a href=" https://leverj.io/assets/documents/l2_tokens_user_agreement.pdf" target="l2_tokens_user_agreement">L2 Tokens User Agreement</a>.
        </p>
        <button onClick={() => agreeFn(true)}>Accept</button>
      </span>
    </div>
  </section>
)

export default Disclaimer
