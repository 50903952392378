import React from 'react'
import ConnectMetamask from './ConnectMetamask'
import Chain from './Chain'

const Footer = ({ data }) => (
  <footer>
    <article>
      <ConnectMetamask />
      <Chain data={data} />
      <div className='copyright'>Leverj &copy; 2020</div>
    </article>
  </footer>
)

export default Footer
