import React from 'react'

const Header = () => (
  <header>
    <div style={{ justifyContent: 'center' }}>
      <a href='/'>
        <img src='leverj-logo.svg' alt='Leverj logo' />
      </a>
    </div>
  </header>
)

export default Header
