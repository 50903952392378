import React, { useState, useEffect } from 'react'
import { NEW_TOKEN, LEGACY_TOKEN } from '../config'
import { formatUnits } from '@ethersproject/units'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

function MuiAlert (props) {
  return <Alert elevation={6} variant='filled' {...props} />
}

export const Migrations = props => {
  const {
    lev,
    levBalance,
    account,
    oldContract,
    newContract,
    chain,
    approvalEvent,
    transferEvent
  } = props?.data

  const [allowance, setAllowance] = useState(0)
  const [approvalInProgress, setAIP] = useState(false)
  const [migrationInProgress, setMIP] = useState(false)

  const [snackbar, setSnackbar] = React.useState({
    open: false
  })

  const levLink = LEGACY_TOKEN.slice(0, 14) + '...' + LEGACY_TOKEN.slice(30, 42)
  const lev2Link = NEW_TOKEN.slice(0, 14) + '...' + NEW_TOKEN.slice(30, 42)
  const levContract = `https://etherscan.io/address/${LEGACY_TOKEN}`
  const lev2Contract = `https://etherscan.io/address/${NEW_TOKEN}`

  const { open } = snackbar

  const handleOpen = newState => () => {
    setSnackbar({
      open: true,
      ...newState
    })
  }

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const approve = async () => {
    console.log('starting approval process...')
    await oldContract.approve(NEW_TOKEN, levBalance)
    setAIP(true)
    handleOpen({
      message: 'Please wait, approval could take a few minutes.',
      duration: 180000, // ms
      severity: 'info'
    })()
  }

  const migrate = async () => {
    await newContract.migrateAll(account)
    setMIP(true)
    handleOpen({
      message: 'Please wait, migration could take a few minutes.',
      duration: 180000, // ms
      severity: 'info'
    })()
  }

  useEffect(() => {
    const checkAllowance = async () => {
      const allowance = await oldContract.allowance(account, NEW_TOKEN)
      const value = parseFloat(formatUnits(allowance, 9))
      value && setAllowance(value)
      value && setAIP(false) // allowance updated, no reason to display AIP
    }

    oldContract && checkAllowance()

    //  allowance updated, time to notify user with new snackbar
    approvalEvent &&
      handleOpen({
        message: 'Success: Token transfer allowance has been updated.',
        duration: 6000, // ms
        severity: 'success'
      })()

    // rtime to notify user with new snackbar
    // and hide "Migration" section
    transferEvent && setMIP(false)
    transferEvent && setAllowance(0)
    transferEvent &&
      handleOpen({
        message: 'Success: You have migrated tokens to the new L2 contract.',
        duration: 6000, // ms
        severity: 'success'
      })()
  }, [
    levBalance,
    oldContract,
    account,
    chain,
    allowance,
    approvalEvent,
    transferEvent
  ])

  return (
    <>
      <section hidden={!lev}>
        <div>
          <span className='title'>Approval</span>
        </div>
        <div>
          <span>
            Please approve transfer of <b>{lev} LEV</b> tokens to the new
            contract.
            <p className='contracts'>
              <a href={levContract} target='_blank' rel='noopener noreferrer'>
                {levLink}
              </a>{' '}
              »{' '}
              <a href={lev2Contract} target='_blank' rel='noopener noreferrer'>
                {lev2Link}
              </a>
            </p>
            <button onClick={approve} disabled={!lev || approvalInProgress}>
              {/*
              // for testing purpose.
              //  Comment the line above and uncomment these lines.
              <button
              onClick={() => {
                handleOpen({
                  message:
                    'Info: You have migrated tokens to the new L2 contract.',
                  duration: 6000, // ms
                  severity: 'warning'
                })()
              }}
              disabled={!lev || approvalInProgress}
            > */}
              Approve
            </button>
            <br />
            <br />
            <i>
              You can change the migrated amount using ‘Edit permission’ in
              Metamask
            </i>
          </span>
        </div>
      </section>

      <section hidden={!allowance}>
        <div>
          <span className='title'>Migration</span>
        </div>
        <div>
          <span>
            Approval of <b>{allowance} LEV</b> tokens to migrate to the new L2
            contract at <b></b> has been confirmed.
            <br />
            <button
              onClick={migrate}
              disabled={!allowance || migrationInProgress}
            >
              Migrate all allowed
            </button>
          </span>
        </div>
      </section>

      <Snackbar
        open={open}
        autoHideDuration={snackbar.duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Migrations
