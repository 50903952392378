import React from 'react'

import {NEW_TOKEN, LEGACY_TOKEN} from '../config'

const knownAddresses = [NEW_TOKEN]

const txReducer = (acc, tx) =>
  knownAddresses.includes(tx.to) ? [...acc, tx] : acc

const TxRow = ({tx, chain}) => {
  const op = tx.to === LEGACY_TOKEN ? 'LEV approval' : 'L2 migration'
  const link = `https://etherscan.io/tx/${tx.hash}`
  const hash = tx.hash && tx.hash.slice(0, 12) + '...' + tx.hash.slice(30, 42)

  return (
    <div>
      <div className='status'>
        <pre>
          {op}:{' '}
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {hash}
          </a>
        </pre>
      </div>
    </div>
  )
}

const TxRows = ({txs, chain}) =>
  txs.map((tx, idx) => <TxRow key={idx} tx={tx} chain={chain}/>)

export const Transactions = ({data}) => {
  const {history, chain} = data

  const txs = history && history.reduce(txReducer, [])
  const hidden = txs.length === 0

  return (
    <div hidden={hidden}>
      <center>
        <h2>Migration transactions history</h2>
      </center>
      <br/>
      <TxRows txs={txs} chain={chain}/>
    </div>
  )
}

export default Transactions
