import React, { useContext } from 'react'
import { BlockchainContext } from './Main'

export const ConnectMetamask = ({ connector }) => {
  const { active, supportedChain } = useContext(BlockchainContext)

  return (
    <div className='status'>
      <button
        onClick={connector}
        hidden={active || !supportedChain}
        className='mm-button'
      >
        Connect Metamask
      </button>
    </div>
  )
}

export default ConnectMetamask
