import React, { useState, useEffect } from 'react'
import './../App.css'
import { useWeb3React } from '@web3-react/core'
import { EtherscanProvider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { Contract } from '@ethersproject/contracts'
import Header from './Header'
import Footer from './Footer'
import Disclaimer from './Disclaimer'
import { formatUnits } from '@ethersproject/units'
import {
  NEW_TOKEN,
  NEW_CONTRACT_ABI,
  LEGACY_TOKEN,
  LEGACY_CONTRACT_ABI,
  SUPPORTED_CHAIN_IDS
} from '../config'
import Balance from './Balance'
import Migrations from './Migrations'
import Transactions from './Transactions'

export const BlockchainContext = React.createContext({})

const chains = {
  1: 'Mainet',
  3: 'Ropsten',
  4: 'Rinkeby',
  5: 'Goerli',
  42: 'Kovan'
}

export const injectedConnector = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS
})

export const Main = props => {
  const [lev, setLev] = useState(0)
  const [lev2, setLev2] = useState(0)
  const [levBalance, setLevBalance] = useState()
  const [newContract, setNewContract] = useState()
  const [oldContract, setOldContract] = useState()
  const [approvalEvent, setApprovalEvent] = useState()
  const [transferEvent, setTransferEvent] = useState()
  const [history, setHistory] = useState([])
  const [disclaimer, setDisclaimer] = useState(false)

  const { chainId, account, activate, active, library } = useWeb3React()

  const supportedChain = SUPPORTED_CHAIN_IDS.includes(chainId)

  useEffect(() => {
    const connectContracts = async () => {
      const lev = await new Contract(
        LEGACY_TOKEN,
        LEGACY_CONTRACT_ABI,
        library.getSigner()
      )
      const lev2 = await new Contract(
        NEW_TOKEN,
        NEW_CONTRACT_ABI,
        library.getSigner()
      )
      setOldContract(lev)
      setNewContract(lev2)
    }

    const getTokenBalance = async token => {
      const bnOld = supportedChain ? await oldContract.balanceOf(account) : 0
      const bnNew = supportedChain ? await newContract.balanceOf(account) : 0
      setLevBalance(bnOld)
      setLev(parseFloat(formatUnits(bnOld, 9)).toPrecision(9))
      setLev2(parseFloat(formatUnits(bnNew, 18)).toPrecision(9))
    }

    const getHistory = async () => {
      // list of all transactions
      const esProvider = new EtherscanProvider(chainId)
      account && esProvider && setHistory(await esProvider.getHistory(account))
      !esProvider && setHistory([])
    }

    const listenEvents = () => {
      const myApprovals =
        oldContract && supportedChain && oldContract.filters.Approval(account)
      const myTransfers =
        oldContract && supportedChain && oldContract.filters.Transfer(account)

      oldContract &&
        supportedChain &&
        library.on(myApprovals, ({ transactionHash }) =>
          setApprovalEvent(transactionHash)
        )

      oldContract &&
        supportedChain &&
        library.on(myTransfers, (from, to, amount, event) =>
          setTransferEvent(from?.transactionHash)
        )

      // remove listener when the component is unmounted
      return () => {
        oldContract && library && library.removeAllListeners(myApprovals)
        oldContract && library && library.removeAllListeners(myTransfers)
      }
    }

    !active && activate(injectedConnector)
    account && !oldContract && chainId && supportedChain && connectContracts()
    active && oldContract && newContract && getTokenBalance()
    library && getHistory()
    library && listenEvents()
  }, [
    chainId,
    active,
    account,
    library,
    newContract,
    oldContract,
    transferEvent,
    approvalEvent,
    supportedChain,
    activate
  ])

  const chain = chains[chainId]

  return (
    <>
      <Header />
      <main>
        <article>
          {!disclaimer ? (
            <Disclaimer agreeFn={setDisclaimer} />
          ) : (
            <>
              <Balance data={{ lev, lev2 }} />
              <Migrations
                data={{
                  levBalance,
                  lev,
                  account,
                  oldContract,
                  newContract,
                  chain,
                  approvalEvent,
                  transferEvent
                }}
              />
              <Transactions data={{ history, chain }} />
            </>
          )}
        </article>
      </main>
      <Footer data={{ chain, account, supportedChain }} />
    </>
  )
}

export default Main
